import React, { Component } from "react";
import "./flowers.css";

class Flowers extends Component {

render()
 {
      
  return (
      <div>
          <div className="row">
              <div className="col s12 m12 l12 center-align">
<div className="flower1-animation">
  <div className="flower-center"></div>
  <div className="stem"></div>
  <div className="leaf leaf-right"></div>
  <div className="leaf leaf-left"></div>
  <div className="petal petal1"></div>
  <div className="petal petal2"></div>
  <div className="petal petal3"></div>
  <div className="petal petal4"></div>
  <div className="petal petal5"></div>
</div>

  <div className="grass">
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>

  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>

  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>

  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>

  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>
  <div className='blade'></div>

</div>

        </div>
            </div>
    
    </div>
  );
 }
}

export default Flowers;